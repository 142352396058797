import * as React from "react"
import Facebook from "../images/facebook.svg"
import Twitter from "../images/twitter.svg"
import Hatena from "../images/hatena.svg"

interface IProps {
  url: string
  title: string
  twitterUsername: string
}

const Social: React.FC<IProps> = ({ url, title, twitterUsername }) => (
  <div className="flex justify-start items-center text-white py-4 mb-4">
    <a
      className="rounded-full h-8 w-8 flex items-center justify-center bg-black mr-4"
      href={`https://twitter.com/intent/tweet?text=${title}&url=${url}&via=${twitterUsername}`}
      target="_blank"
      rel="noopener"
    >
      <Twitter fill="white" />
    </a>
    <a
      className="rounded-full h-8 w-8 flex items-center justify-center bg-black mr-4"
      href={`http://www.facebook.com/share.php?u=${url}`}
      target="_blank"
      rel="noopener"
    >
      <Facebook fill="white" />
    </a>
    <a
      href={`http://b.hatena.ne.jp/entry/${url}`}
      className="rounded-full h-8 w-8 flex items-center justify-center bg-black mr-4"
      data-hatena-bookmark-layout="simple"
      title={title}
    >
      <Hatena fill="white" />
    </a>
  </div>
)

export default Social
