import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Social from "../components/social"

interface Props {
  data: {
    markdownRemark: any
    site: {
      siteMetadata: {
        title: string
        siteUrl: string
        social: {
          twitter: string
        }
      }
    }
  }
  location: Location
  pageContext: any
}

const BlogPostTemplate = ({ data, pageContext, location }: Props) => {
  const post = data.markdownRemark
  const {
    title,
    siteUrl,
    social: { twitter },
  } = data.site.siteMetadata
  const { previous, next } = pageContext
  const url = siteUrl + location.pathname
  const featuredImage = post.frontmatter.featuredImage
    ? post.frontmatter.featuredImage.childImageSharp.fluid
    : null
  const ogImage = post.frontmatter.ogImage
    ? post.frontmatter.ogImage.childImageSharp.fixed
    : null

  return (
    <Layout title={title} twitter={twitter}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={ogImage && ogImage.src}
        url={url}
      />
      <article>
        <header>
          <header className="pb-4">
            <h2 className="text-xl font-medium mr-2">
              {post.frontmatter.title}
            </h2>
            <small>
              <time className="leading-tight align-bottom">
                {post.frontmatter.date}
              </time>
            </small>
          </header>
        </header>
        {featuredImage && <Image fluid={featuredImage} />}
        <section
          className="markdown pb-12"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <footer>
          <Social
            twitterUsername={twitter}
            url={url}
            title={post.frontmatter.title}
          />
          {/* <Bio /> */}
        </footer>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        social {
          twitter
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ogImage {
          childImageSharp {
            fixed(height: 630, width: 1200) {
              src
            }
          }
        }
      }
    }
  }
`
